<template>
  <div id="account-management">
    <QuillEditor theme="snow" 
    id="conts"
    v-model="content" 
    :options="editorOption" 
    ref="QuillEditorRef" 
    />
    <!-- <input type="file" style="display: none" id="input" @change="fileData" accept=".mp4,.3gp,.wmv,.jpg,.jpeg,.png,.gif"/> -->
    <input type="file" style="display: none" id="input" @change="fileData" accept=".jpg,.jpeg,.png,.gif"/>
    
    <BeforeSubmitUpload
      ref="beforeSubmitUploadRef"
      @all-file-success="submitFormValues"
      @upload-error="handleUploadError"
      />

    <PageLoading :loading="loading" />
  </div>
</template>

<script>
import { reactive, defineEmits, defineExpose, ref, h, defineComponent ,defineProps, watch  } from "vue";
import { NSpace, NButton, NTime, useMessage } from "naive-ui";

import BeforeSubmitUpload from '@/components/RichTextSubmitUpload/BeforeSubmitUpload.vue';
import teachingTypesMap from "@/enumerators/account-status.js";
import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import { uploadIndex } from '@/api/upload.js';
import { GetProblemDetail, PostBannerState, PostBannerDelete, PostBannerSort, PostBannerTempUpload } from "@/api/problem.js";  
import { toRaw } from '@vue/reactivity'
import PageLoading from "@/components/PageLoading/index.vue";
import { getFileUniqueStr } from '@/utils/utils.js';

import { QuillEditor, Quill  } from '@vueup/vue-quill'
import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
Quill.register("modules/ImageExtend", ImageExtend);



export default defineComponent({
  components: {
    QuillEditor,
    PageLoading,
    BeforeSubmitUpload
  },
  props: {
    msg: Boolean,
    id: String
  },
  setup: (props,context) => {
    // 监听是否开始传值
    var setinit = setInterval(function(){
      if(props.msg){
        // console.log("监听1",props.msg)
        // console.log("HTML",quill.root.innerHTML)
        context.emit('todata', QuillEditorRef.value.getHTML())
      }else{
        // props.msg = false
        // console.log("监听2",props.msg)
      }
    },1000)
    
    // 获取广告详情
    function name(params) {
      GetProblemDetail({"Problems[id]":params}).then(res=>{
        let html = document.querySelector("#conts").childNodes[0];
        html.innerHTML = res.data.introduce
      })
      .catch(rej=>{})
    }
    // 如果是编辑 则初始化数据
    if(props.id){
      name(props.id)
    }
    
    const { SUCCESS } = resStatusEnum;
    const message = useMessage();
    const loading = ref(false);
    const content = ref('')
    const imagedata = ref([])
    const beforeSubmitUploadRef = ref(null);
    const QuillEditorRef = ref(null);
    container.pop();
    container.push(['link', 'image']);
    return { 
      content,
      message,
      editorOption: {
        modules: {
          toolbar: {
            container: container,
            handlers: {
              image: function (istrue) {
                if(istrue){
                  document.querySelector('#input').click()
                }
                // QuillWatch.emit(this.quill.id);
                // console.log("劫持图片点击按钮事件")
              },
            },
          },
        },
      },
      loading,
      fileData,
      handleUploadError,
      submitFormValues,
      beforeSubmitUploadRef,
      QuillEditorRef
    }
    // 上传文件
    function fileData(e) {
      let isNO = true
      var fileData = e.target.files[0]
      // 根据文件后缀 效验大小(因fileData.type数据不全)
      var fileDataName = fileData.name
      var Dindex= fileDataName.lastIndexOf(".");
      var Ext = fileDataName.substr(Dindex+1);
      var fileDataType = 0  // 文件类型  1图片  2视频
      // 限制图片大小10M
      if(Ext!='mp4'&&Ext!='mkv'&&Ext!='avi'&&Ext!='3gp'&&Ext!='flv'&&Ext!='wmv'&&Ext!='jpg'&&Ext!='jpeg'&&Ext!='png'&&Ext!='gif'){
        message.info("不支持"+Ext+"文件格式!!!")
        isNO = false
      }
      else if(Ext=='jpg'||Ext=='jpeg'||Ext=='png'||Ext=='gif'){
        fileDataType = 1
        if(fileData.size >(1018576 * 5) ){
          message.info("图片大小不能超过" +"5M" )
          isNO = false
        }
      }
      else if(Ext=='mp4'||Ext=='mkv'||Ext=='avi'||Ext=='3gp'||Ext=='flv'||Ext=='wmv'){
        fileDataType = 2
        if(fileData.size >(1018576 * 500 * 4.096) ){
          message.info("视频大小不能超过" +"2GB" )
          isNO = false
        }
      }
      if(isNO){
        loading.value = true;
        let uploadParamsMap = {
          file:fileData,
          ext:"."+Ext,
          fileType:fileDataType,
          name: fileDataName,
          fileUniqueStr:getFileUniqueStr(fileData),
          upComplete: false,
          saved:false,
          savedId:'',
          size:fileData.size,
          percentage: 0,
          videoId:""
        };
        let uploadParamsMapArr = [uploadParamsMap]
        beforeSubmitUploadRef.value.startUpload(uploadParamsMapArr);

      }
    }
    function submitFormValues (fileData) {
      if(fileData.length>0){
      // 上传临时文件
      let data = {
        "Banner[upload][file_url]":fileData[0].fileUrl,
        "Banner[upload][file_name]":fileData[0].fileUniqueStr+fileData[0].ext,
        "Banner[upload][file_size]":fileData[0].size,
        "Banner[upload][extension]":fileData[0].ext,
        "Banner[upload][file_type]":fileData[0].fileType
      }
      PostBannerTempUpload(data).then(resg=>{
        // 图片添加到富文本
        // var conts = document.querySelector("#conts");
        // var quill = new Quill(conts);
        // let Label = ''
        const quill = QuillEditorRef.value.getQuill();
        let index = 0;
        const range = quill.getSelection();
        if (range) {
          index = range.index;
        }
        if(fileData[0].fileType==1){
          quill.insertEmbed(index, 'image', fileData[0].fileUrl);
          // Label = `<p><img src="${fileData[0].fileUrl}" id=${resg.data.id}/></p>`
        }else{
          quill.insertEmbed(index, 'video', fileData[0].fileUrl);
          // Label = `<p><iframe src="${fileData[0].fileUrl}" controls="controls" style="height:100%" frameborder="0" allowfullscreen="true" id=${resg.data.id}></iframe></p>`
        }
        // let html = document.querySelector("#conts").childNodes[0];
        // html.innerHTML += Label
        // quill.root.innerHTML += Label
        loading.value = false
      }).catch(function (err) {
        console.log("err",err)
      });
      }
    };
    function handleUploadError () {
      message.info("有文件上传失败，返回")
    };
  }
})

setTimeout(function(){
  document.querySelector('.ql-image').title = '上传图片视频';
  // document.querySelector('.ql-video').title = '导入视频';
},2000)
</script>

<style>
#account-management {
  height: 470px;
}
/* .ql-video {
  display: none !important;
} */
</style>