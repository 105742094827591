<template>
  <div class="alone-possess">
    <NCard size="small" :border="false" :segmented="{ content: 'hard' }" :title="title">
      <NForm
        ref="formRef"
        label-placement="left"
        :label-width="120"
        require-mark-placement="left"
        :model="formValues"
        :rules="formRules"
      >
        <NFormItem label="标题：" path="Problems[title]" show-require-mark>
          <NInput
            style="width: 300px;"
            maxlength="50"
            placeholder="请输入标题"
            show-count
            clearable
            v-model:value="formValues['Problems[title]']"
          />
        </NFormItem>

        <NFormItem label="排序：" path="Problems[sort]" >
          <n-input-number :show-button="false" :max="999" v-model:value="formValues['Problems[sort]']" style="width:300px" placeholder="请输入序号，数字越小，顺序越靠前" @update:value="NumberSort" />
        </NFormItem>
        
        <NFormItem label="详情：" path="Problems[title]" show-require-mark id="RichText" >
          <!-- 富文本 -->
          <RichText :msg="isPassValue" @todata="todata" :id="IDD"/>
        </NFormItem>
        
        <div class="banner-btns">
          <NSpace>
            <NButton @click="handleBack">返回</NButton>
            <NButton type="primary" @click="handleSave">确定</NButton>
          </NSpace>
        </div>

      </NForm>
    </NCard>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useMessage } from 'naive-ui';
  import { CloseCircleSharp as CashIcon } from '@vicons/ionicons5'
  
  import UPheadPortrait from '@/components/UPheadPortrait/index.vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { PostBannerUpdate, PostBannerCreate, GetProblemDetail } from '@/api/problem.js';  // 编辑提交 新增提交 详情
  import { resStatusEnum } from '@/enumerators/http.js';
  import RichText from '@/components/Problem/index.vue';

  const isPassValue = ref(false)  // 富文本是否开始传值
  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const loading = ref(false);
  const route = useRoute();
  const router = useRouter();
  const teachingId = route.query.id;
  const IDD = ref(teachingId)

  const isEdit = !!teachingId;

  const formRef = ref(null);
  
  const title = ref("")
  
  title.value = teachingId?'问题编辑':'问题新增'


  // 编辑头像时原有文件列表
  const coverImg = []

  const formValues = ref({
    "Problems[id]":"",        //id
    "Problems[title]":"",     //标题
    "Problems[system]":[],    //系统id
    "Problems[sort]":1, //排序 
  });

  const formRules = {
    "Problems[title]": {
      required: true,
      message: '必填',
      trigger: 'blur'
    }
  };

  const NumberSort = ()=>{
    let num = formValues.value['Problems[sort]']
    if(num===0){
      message.info("排序从1开始")
      formValues.value['Problems[sort]'] = 1
    }
    else if(num%1 === 0){
    }else{
      message.info("排序值必须是整数")
      formValues.value['Problems[sort]'] = ''
    }
  }

  // 返回事件
  const handleBack = () => {
    window.location.href="/problems/index/index"; 
  };
  // 提交事件
  const handleSave = () => {
    loading.value = true;
    // 添加删除参数
    isPassValue.value = true
    setTimeout(function () {
      submitFormValues()
    },2000)
  };

  // 编辑时 提交数据
  const submitFormValues = fileList => {
    if (isEdit) {
      loading.value = true;
      PostBannerUpdate(formValues.value).then(res => {
        if (res.code === SUCCESS) {
          message.success(res.msg);
          handleBack()
        }else{
          loading.value = false;
          // message.info(res.msg);
          isPassValue.value = false
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    }else{
      // 新增时提交
      PostBannerCreate(formValues.value).then(res => {
        if (res.code === SUCCESS) {
          message.success(res.msg);
          handleBack()
        }else{
          loading.value = false;
          isPassValue.value = false
        }
      }).catch(err => {}).finally(() => {
        loading.value = false;
        isPassValue.value = false
      });
    }
  };

  // 编辑时初始化
  if (isEdit) {
    loading.value = true;
    GetProblemDetail({
      'Problems[id]': teachingId
    }).then(res => {
      loading.value = false;
      formValues.value["Problems[id]"] = res.data.id
      formValues.value["Problems[title]"] = res.data.title
      formValues.value["Problems[sort]"] = Number(res.data.sort)
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  }

  // 监听子组件传来的值
  // 提交事件
  const todata = (data)=>{
    // console.log("监听子组件传来的值",data);
    formValues.value["Problems[introduce]"] = data          // 富文本数据 

    // 获取所有图片的src
    var imgList = [];
    data.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
      imgList.push(capture);
    });
    data.replace(/<iframe [^>]*src=['"]([^'"]+)[^>]*>/g, (match, capture) => {
      imgList.push(capture);
    });
    // console.log("监听子组件传来的值",imgList)
    formValues.value["Problems[upload_ids]"] = imgList      // 所有图片地址
  }

</script>
<style scoped>
.banner-btns {
  margin-top:70px;
  margin-left: 120px;
}
.banner-btns .n-space button{
  width:120px;
}
#RichText .n-form-item-blank{
  display: block !important
}
/* 隐藏视频上传按钮 */

</style>
<style lang="less">
.alone-possess .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
  width: 177px !important;
  height:100px;
  position: relative;
  background-size: 100% 100% !important;
}
.alone-possess .title-up-text {
  left: 200px !important;
}
.alone-possess .n-upload-file-list .n-upload-file.n-upload-file--image-card-type{
  width: 177px !important;
}
.alone-possess .title-up-text{
  width: 144px !important;
}
.alone-possess .n-card-header .n-card-header__main{
  font-weight: 700;
}
</style>